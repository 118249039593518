<template>
  <div>
    <h3 class="mt-2 eg-title">{{ title }}:</h3>
    <div v-for="group in enrollGroups" :key="group.id" class="fill-grey eg-item">
      <div class="row">
        <div class="col-md-9 fill-grey eg-item-grid">
          <h3 class="eg-item__title">{{ group.title }}</h3>
          <div class="eg-item__description">
            {{ group.description }}
          </div>
        </div>
        <div class="col-md-3 eg-item-grid">
          <div class="eg-item__places" v-if="!wasChoose">
            <div>Мест: {{ group.max_enroll_count - group.enroll_count }}</div>
            <button @click.prevent="choose(group)" type="button" class="btn btn-primary">Выбрать</button>
          </div>
          <div class="eg-item__places" v-else>
            <div class="d-flex align-items-center flex-1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34874 20.9425 6.80691 19.0678 4.93219C17.1931 3.05746 14.6513 2.00295 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C19.9976 14.121 19.1539 16.1544 17.6542 17.6542C16.1544 19.1539 14.121 19.9976 12 20ZM14.0981 9.63379L13 10.2681V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V12C11 12.1755 11.0463 12.3479 11.1341 12.4999C11.2218 12.6519 11.3481 12.7782 11.5 12.8659C11.652 12.9537 11.8245 13 12 13C12.1755 13 12.348 12.9539 12.5 12.8662L15.0981 11.3662C15.212 11.3006 15.3117 11.2132 15.3918 11.109C15.4718 11.0048 15.5305 10.8858 15.5646 10.7589C15.5986 10.632 15.6073 10.4997 15.5902 10.3694C15.5731 10.2392 15.5305 10.1135 15.4648 9.99974C15.3991 9.88595 15.3117 9.78622 15.2074 9.70624C15.1032 9.62626 14.9842 9.56761 14.8573 9.53362C14.7304 9.49964 14.598 9.49099 14.4678 9.50818C14.3375 9.52537 14.2119 9.56805 14.0981 9.63379Z" fill="#002E5E"/>
              </svg>
              <div class="ml-1">
                {{ group.start_date | formatDate('DD.MM.YYYY') }} - {{ group.end_date | formatDate('DD.MM.YYYY') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";
import {mapActions} from "vuex";

export default {
  name: "AcceleratorEnrollGroupItem",
  props: {
    title: {
      type: Object,
      default() {
        return 'Выберите поток обучения'
      }
    },
    enrollGroups: {
      type: Array,
      required: true
    },
    wasChoose: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    async choose(group) {
      await ApiService.postJson('acceleratorenrollgroups/mies', {
        group: group.id
      })
      await this.getEnrollGroups()
    },
    ...mapActions(['getEnrollGroups'])
  }
}
</script>

<style lang="scss">
.eg-title {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  margin-bottom: 2rem!important;
  @media (max-width: 576px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px;
  }
}
.eg-item {
  margin: 0 -4.8rem 0;
  padding: 2.4rem 4.8rem;
  .row {
    border-left: 2px solid #BDBDBD;
    margin: 0;
    .eg-item-grid {
      &:first-child {
        border-right: 2px solid #BDBDBD;
      }
    }
  }
  @media (max-width: 576px) {
    padding: 16px;
    margin: 0 -16px;
    .row {
      border: none;
      .eg-item-grid {
        padding: 0;
        border-right: none!important;
      }
    }
  }
  &__title {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem;
    margin-bottom: 2rem!important;
    @media (max-width: 576px) {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }
  &__places {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    @media (max-width: 576px) {
      margin-top: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>