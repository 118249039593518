<template>
  <div class="accelerator-menu row">
    <div class="col-md-3" v-for="(menu, index) in acMenu" :key="index">
      <router-link tag="div" :to="menu.link" class="accelerator-menu__item p-24" :class="{success: menu.success}">
        <div class="accelerator-menu__item__circle mb-24">{{ index + 1 }}</div>
        <div>{{ menu.name }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AcceleratorMenu",
  computed: {
    ...mapGetters(['acMenu', 'acceleratorEnroll', 'organization', 'acceleratorMembers', 'acceleratorProfile'])
  },
  methods: {
    ...mapActions(['updateAcMenu'])
  },
  watch: {
    organization: {
      handler: function (val) {
        if(val && val.ogrn) {
          this.updateAcMenu({route: 'AcceleratorCompany', success: true});
        }
      },
      deep: true,
      immediate: true
    },
    acceleratorMembers: {
      handler: function (val) {
          this.updateAcMenu({route: 'AcceleratorMember', success: val.length > 0});
      },
      deep: true,
      immediate: true
    },
    acceleratorProfile: {
      handler: function (val) {
        this.updateAcMenu({route: 'AcceleratorProfile', success: val.id});
      },
      deep: true,
      immediate: true
    },
    acceleratorEnroll: {
      handler: function (val) {
        this.updateAcMenu({route: 'AcceleratorDocs', success: val.contract && val.forward && val.memorandum});
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style lang="scss">
.accelerator-menu {
  $icons-main: url('/images/icons-main.png') no-repeat;
  &__item {
    height: 18rem;
    cursor: pointer;
    background: #FBFBFB;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem;
    &__circle {
      height: 6rem;
      line-height: 6rem;
      width: 6rem;
      color: #fff;
      text-align: center;
      border-radius: 5rem;
      background: #1C75BC;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 350;
    }
    &.active {
      background: #1C75BC;
      color: #fff;
      .accelerator-menu__item__circle {
        color: #1C75BC;
        background: #fff;
      }
    }
    &.success {
      .accelerator-menu__item__circle {
        border: #323232 3px solid;
      }
    }
  }
}
</style>