<template>
  <div class="mt-5">
    <div class="text-center" v-if="acceleratorEnroll.status === 'send'">
      <img src="/pic/accelerator/send.svg" />
      <h3 class="mt-2">Ваша заявка отправлена</h3>
      <p>На указанный Вами адрес электронной почты придет письмо, <br/>как только заявка будет рассмотрена. </p>
    </div>
    <div v-if="acceleratorEnroll.status === 'approved'">
      <base-modal id="decline" :type="'bg-danger'" title="Вы уверены, что хотите отказаться от участия в акселераторе?">
        <template v-slot:body>
          <div class="mt-4">
            Ваша заявка будет отклонена, результаты домашних заданий - аннулированы
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Отмена</button>
            <button type="button" class="btn btn-outline-danger align-self-end" @click="chooseDecline()">Подтвердить</button>
          </div>
        </template>
      </base-modal>
      <template v-if="!acceleratorEnroll.group">
        <accelerator-enroll-group-item :enroll-groups="enrollGroups" />
        <div class="separator"></div>
        <div class="d-lg-flex justify-content-end d-md-block">
          <button type="button" class="btn btn-outline-danger" @click="decline()">Отказаться от участия</button>
        </div>
      </template>
      <div v-else>
        <accelerator-enroll-group-item title="Выбран поток обучения" :was-choose="true" :enroll-groups="[acceleratorEnroll.group]" />
        <div class="notice notice_red mb-3 mt-5">
          <h2 class="notice__title">Внимание!</h2>
          <div class="notice__content">
            После выбора потока зарегистрированным сотрудникам на указанные электронные адреса будут направлены договоры об участии, а также ссылка и приглашение на мероприятие.
          </div>
        </div>
      </div>
    </div>
    <div v-if="acceleratorEnroll.status === 'failure'" class="text-center">
      <img src="/pic/accelerator/decline.svg" />
      <h3 class="mt-2">Вы отказались от участия</h3>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import {mapActions, mapGetters} from "vuex";
import BaseModal from "@/components/modal/BaseModal.vue";
import AcceleratorEnrollGroupItem from "@/components/profile/accelerator/AcceleratorEnrollGroupItem.vue";

export default {
  name: "AcceleratorStatus",
  components: {AcceleratorEnrollGroupItem, BaseModal},
  computed: {
    ...mapGetters(['acceleratorEnroll', 'enrollGroups'])
  },
  methods: {
    ...mapActions(['updateAcceleratorEnroll']),
    decline() {
      $('#decline').modal('show')
    },
    chooseDecline() {
      $('#decline').modal('hide')
      this.updateAcceleratorEnroll({status: 'failure'})
    }
  }
}
</script>

<style lang="scss">
.btn-outline-danger {
  @media (max-width: 576px) {
    display: block;
    width: 100%;
  }
}
</style>