<template>
  <div class="section__lk-content container-shadow fill-white pb-5">
    <div class="section__lk-title">Заявка на участие в федеральном акселераторе</div>
    <template v-if="loaded">
      <template v-if="noSend">
        <accelerator-menu />
        <div class="d-flex">
          <div class="section__lk-content__left">
            <div class="mt-5" v-if="acceleratorEnroll.comments.length">
              <h3>Комментарии от администратора</h3>
              <admin-comments :comments="acceleratorEnroll.comments"/>
            </div>
            <router-view />
          </div>
        </div>
      </template>
      <accelerator-status v-else />
    </template>
  </div>
</template>

<script>
import AcceleratorMenu from "@/components/profile/accelerator/AcceleratorMenu.vue";
import {mapActions, mapGetters} from "vuex";
import AdminComments from "@/components/AdminComments.vue";
import AcceleratorStatus from "@/components/profile/accelerator/AcceleratorStatus.vue";

export default {
  name: "AcceleratorCompany",
  components: {AcceleratorStatus, AdminComments, AcceleratorMenu},
  data() {
    return {
      loaded: false
    }
  },
  async mounted() {
    await this.getAcceleratorEnroll()
    if(this.noSend) {
      await this.getAcceleratorMembers()
      await this.getAcceleratorProfile()
      await this.getCountries()
      await this.getSpecializations()
    } else {
      await this.getEnrollGroups()
    }
    this.loaded = true
  },
  methods: {
    ...mapActions(['getAcceleratorEnroll', 'getOrganization', 'getAcceleratorMembers', 'getAcceleratorProfile', 'getCountries', 'getSpecializations', 'getEnrollGroups'])
  },
  computed: {
    ...mapGetters(['acceleratorEnroll']),
    noSend() {
      return !this.acceleratorEnroll.status || this.acceleratorEnroll.status === 'draft' || this.acceleratorEnroll.status === 'rejected'
    }
  }
}
</script>

<style scoped>

</style>